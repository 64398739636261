import "./Home.scss";

export default function Home() {
    return (
        <div className="home">
            <a href="https://lightweight.page.link/tower-royale" target="_blank" rel="noreferrer">
                <img src="/tower_royale.png" alt="Tower Royale" />
                <h1>Tower Royale</h1>
                <p>Super fantastic PvP tower defense game for everyone to enjoy.</p>
            </a>
        </div>
    );
}
