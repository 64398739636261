import { createContext, useContext } from 'react';

import BlocBase from './BlocBase';
import PopularDeckListBloc from './stats/PopularDeckListBloc';

export class Blocs {
    initialized = false;

    popularDeckListBloc = new PopularDeckListBloc();

    blocQueue: BlocBase[] = [
        this.popularDeckListBloc,
    ];

    init() {
        if (this.initialized) return;

        console.log("[blocsContext.ts] Initializing all blocs");

        this.blocQueue.forEach((x) => x.init());

        this.initialized = true;
    }

    dispose() {
        console.log("[BlocsContext.ts] Disposing blocs");

        this.blocQueue.forEach((x) => x.dispose());
    }
}

export const blocs = new Blocs();

export const BlocsContext = createContext(new Blocs());

export function useBlocsContext() {
    return useContext(BlocsContext);
}
