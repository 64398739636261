import "./App.scss";

import { BlocsContext, blocs } from "./blocs/BlocsContext";
import { useEffect, useState } from "react";

import MyRoutes from "./MyRoutes";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";

function App() {
    const [noHeader, setNoHeader] = useState(false);
    const { search } = useLocation();

    useEffect(() => {
        blocs.init();
    }, []);

    useEffect(() => {
        const noHeader = new URLSearchParams(search).get("no-header");
        setNoHeader(noHeader !== null);
    }, [search]);

    return (
        <div className="App">
            <BlocsContext.Provider value={blocs}>
                {!noHeader && (
                    <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
                        <Navbar.Brand className="font-weight-bold" href="/">
                            Tower Royale
                        </Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Nav.Link href="/popular-decks">Popular Decks</Nav.Link>
                        </Navbar.Collapse>
                    </Navbar>
                )}
                <div className="container">
                    <MyRoutes />
                </div>
            </BlocsContext.Provider>
        </div>
    );
}

export default App;
