import axios from "axios";
import { BehaviorSubject } from "rxjs";

import BlocBase from "../BlocBase";
import { CROW_API_ENDPOINT } from "src/Config";

export type DeckEntry = {
    deck: string;
    count_win: number;
    count_loss: number;
    count_total: number;
    win_rate: number;
    avg_wave: number;
};

export type PopularDeckList = {
    [tier: number]: DeckEntry[];
};

export default class PopularDeckListBloc extends BlocBase {
    private _prevQuery = "";
    private _loading$ = new BehaviorSubject<boolean>(false);
    private _popularDeckList$ = new BehaviorSubject<PopularDeckList>({});

    init() {}

    query(mode: string) {
        const url = `${CROW_API_ENDPOINT}/popular-decks/random-tower/${mode}`;

        if (url === this._prevQuery) return

        console.log(url)

        this._prevQuery = url;
        this._loading$.next(true);
        this._popularDeckList$.next({});

        axios
            .get(url)
            .then((value) => {
                this._loading$.next(false);
                this._popularDeckList$.next(value.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    dispose() {
        this._loading$.complete();
        this._popularDeckList$.complete();
    }

    get loading$(): BehaviorSubject<boolean> {
        return this._loading$;
    }

    get popularDeckList$(): BehaviorSubject<PopularDeckList> {
        return this._popularDeckList$;
    }
}
