import { CROW_API_ENDPOINT } from "src/Config";

abstract class BlocBase {
    abstract init(): void;

    abstract dispose(): void;
}

export function make_url_query(
    func: string,
    mode: string,
    bins: string,
    dateFrom: string,
    dateTo: string,
) {
    let url = `${CROW_API_ENDPOINT}/${func}/random-tower?bins=${bins}`;

    if (mode) url += `&mode=${mode}`;
    if (dateFrom) url += `&date_from=${dateFrom}`;
    if (dateTo) url += `&date_to=${dateTo}`;

    return url;
}

export default BlocBase;
