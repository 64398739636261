import { Route, Routes } from "react-router-dom";

import asyncComponent from "./components/AsyncComponent";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";

export default function MyRoutes() {
    const PopularDecks = asyncComponent(() => import("./containers/PopularDecks"))

    return (
        <Routes>
            <Route element={<Home />} exact path="/" />
            <Route element={<PopularDecks />} exact path="/popular-decks" />
            <Route element={<NotFound />} path="*" />
        </Routes>
    );
}
