import React, { Component } from "react";

export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                component: null,
            };

            this.mounted = false;
        }

        async componentDidMount() {
            this.mounted = true;

            const { default: component } = await importComponent();

            if (!this.mounted) return;

            this.setState({
                component: component,
            });
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            if (!this.mounted) return null;

            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }

    return AsyncComponent;
}
